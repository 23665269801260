import { format, parseISO, differenceInMilliseconds } from 'date-fns';

export const isBrowser = () => typeof window !== 'undefined';

export const siteName = 'Frame.io Developer';

export function formatDate(date, fmt = 'EEEE LLLL do yyyy') {
  const currentTime = new Date();
  return format(parseISO(date + currentTime.getTimezoneOffset()), fmt);
}

export function formatDateAccurate(date, fmt = 'MMM do, yyyy H:mm:ss aa') {
  const currentTime = new Date();
  return format(parseISO(date + currentTime.getTimezoneOffset()), fmt);
}

export function timeDiff(startTime, endTime) {
  return differenceInMilliseconds(parseISO(startTime), parseISO(endTime));
}

export const userIsAdminOrOwner = (roles) =>
  Object.values(roles).some(
    ({ account: role }) => role === 'admin' || role === 'owner'
  );
